/* Color Scheme */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root,
#root .App,
#root .App > div {
  height: 100vh;
}
#root .main-content {
  height: calc(100vh - 70px);
  min-height: 730px;
}
#root .footer-area {
  height: 70px;
  padding-top: 20px;
}
#root .footer-area p {
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: center;
}
a {
  cursor: pointer;
  color: #075770;
}
/* Top Nav Bar */
.nav-bar-wrapper,
.nav-bar-wrapper .right-separator {
  height: 64px;
}
.nav-bar-wrapper .right-separator {
  border-right: 1px solid black;
}
.nav-bar-wrapper .right-separator a,
.nav-bar-wrapper .right-separator p {
  color: white;
  text-decoration: none;
  margin: 24px 15px;
  display: block;
}
.login-form button[type="submit"],
.error-modal button {
  background-color: #075770;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
body,
h1 {
  font-family: 'Open Sans', sans-serif;
}
/* Login View */
.login-main-grid {
  padding-top: 50px;
  margin-top: 0 !important;
  height: 100vh;
  background-image: url("./assets//img//landscape.jpeg");
  /* The image used */
  background-position: top 0 left 0;
  /* Show embedded logo in the bg image on top left */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
}
.login-main-grid h2 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}
.login-main-grid .header-row {
  height: 120px;
}
.login-main-grid .main-area {
  height: 100%;
}
.login-main-grid h1 {
  color: white;
  text-shadow: black 0.1em 0.1em 0.2em;
  font-size: 34px;
  text-align: center;
}
.login-main-grid .login-form {
  margin-top: 20px !important;
  margin: 0 auto;
  padding: 10px 20px 30px 20px;
  float: none;
  width: 400px;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.65);
}
.login-main-grid .login-form h2 {
  text-align: center;
}
.login-main-grid .login-form .agreement-desc {
  font-size: 0.8rem;
}
.login-main-grid .forget-password-modal {
  width: 100%;
}
.login-main-grid .forget-password-modal a {
  margin-top: 15px;
  float: right;
}
.login-main-grid .signup-bn-wrapper {
  border-top: 1px solid;
}
.login-main-grid .signup-bn-wrapper,
.login-main-grid .signup-bn-wrapper a {
  margin-top: 15px;
  text-decoration: none;
}
header {
  background: linear-gradient(to bottom, #2c3742 0%, #28303a 100%) !important;
}
.header-title a {
  text-transform: uppercase;
  color: white !important;
  font-weight: bold;
  text-decoration: none;
}
/* Home Page */
.home-view {
  padding-top: 100px;
  padding-left: 100px;
  padding-right: 100px;
}
.home-view h1 {
  font-size: 30px;
  margin-bottom: 50px;
}
a.open-map-bn {
  height: 128px;
}
.map-buttons-wrapper {
  max-width: 1170px;
}
.home-view .map-buttons-wrapper > div,
.home-view .map-buttons-wrapper > a {
  width: 585px;
}
.top-padding {
  display: block;
  height: 200px;
}
/* Modals */
.modal-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: white;
  border-radius: 5px;
}
.modal-header,
.modal-content,
.modal-footer {
  padding-left: 20px;
  padding-right: 20px;
}
.modal-area:focus-visible {
  outline: none;
}
.modal-header h3 {
  font-weight: 500;
  font-size: 1.25rem;
}
.modal-content > p > div {
  width: 100%;
}
.modal-content {
  padding-bottom: 30px;
  border-bottom: 1px solid #dee2e6;
}
.modal-footer {
  padding-top: 10px;
  padding-bottom: 20px;
}
.modal-footer button {
  padding: 10px;
}
/* Loading Modal */
.loading-modal h3 {
  text-align: center;
}
/* Loading Spinner */
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #77C9F2;
  background: -moz-linear-gradient(left, #77C9F2 10%, rgba(243, 114, 73, 0) 42%);
  background: -webkit-linear-gradient(left, #77C9F2 10%, rgba(243, 114, 73, 0) 42%);
  background: -o-linear-gradient(left, #77C9F2 10%, rgba(243, 114, 73, 0) 42%);
  background: -ms-linear-gradient(left, #77C9F2 10%, rgba(243, 114, 73, 0) 42%);
  background: linear-gradient(to right, #77C9F2 10%, rgba(243, 114, 73, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #77C9F2;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* Signup Page */
.signup-view .signup-form > div {
  margin-bottom: 10px;
  margin-top: 16px;
}
.signup-view .signup-form > div:nth-child(6) {
  height: 0;
  margin: 0;
  padding: 0;
  float: left;
  border: 0;
}
.signup-view .signup-form > div:nth-child(6) input,
.signup-view .signup-form > div:nth-child(6) fieldset,
.signup-view .signup-form > div:nth-child(6) legend span {
  height: 0;
  margin: 0;
  padding: 0;
  float: left;
  border: 0;
}
.signup-view .signup-view {
  padding-top: 150px !important;
}
.signup-view .signup-view .header-row {
  height: 100px;
}
.signup-view .signup-view h1,
.signup-view .signup-form h2 {
  text-align: center;
}
.signup-view .signup-form > div:nth-child(4),
.signup-view .signup-form > div:nth-child(4) div,
.signup-view .signup-form > div:nth-child(4) input,
.signup-view .signup-form > div:nth-child(4) fieldset,
.signup-view .signup-form > div:nth-child(4) legend,
.signup-view .signup-form > div:nth-child(4) span {
  height: 0;
  margin: 0;
  padding: 0;
  width: 0;
  float: left;
}
/* Forget password modal */
/* User Profile View */
.user-profile-view {
  padding-top: 200px;
}
.profile-data {
  max-width: 600px;
  margin: 0 auto;
}
.profile-data > div,
.profile-data > button {
  margin-bottom: 20px;
}
/* Change password form */
.change-password-form > div {
  margin-bottom: 20px;
}
/* User Preferences view */
.user-preferences-view {
  padding-left: 100px;
  padding-right: 100px;
}
.user-preferences-view a:hover {
  filter: brightness(120%);
}
.user-preferences-view .MuiGrid-grid-xs-6,
.user-preferences-view .submit-bn-row {
  padding: 20px;
}
.user-preferences-view .submit-bn-row {
  padding-top: 50px;
}
